import React from 'react'

import {
  Box,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { sanitize } from 'dompurify'

import dateTime from '@/utils/dateTime'

import { Props } from './types'

function ResourceCard({ article }: Props): React.ReactElement {
  const {
    id,
    title,
    content,
    publishedAt,
  } = article

  const sanitizedContent = sanitize(content || '')
  const contentHtml = document.createElement('div')
  contentHtml.innerHTML = sanitizedContent

  return (

    <Stack
      height='100%'
    >
      <Stack>
        <Typography variant='body3' color='text.secondary'>{dateTime.format((publishedAt || new Date()), 'MM.dd.yyyy')} - Common Sense Media</Typography>
        <Link
          component={RouterLink}
          to={`/dashboard/resources/${id}`}
          sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }}
        ><Typography variant='h6'>{title}</Typography>
        </Link>
      </Stack>
      <Box sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      >
        <Typography
          sx={{
            width: '40ch',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            '-webkit-line-clamp': 4,
            '-webkit-box-orient': 'vertical',
          }}
          my={2}
        >
          {contentHtml.innerText.slice(0, 150)}{contentHtml.innerText.length > 150 ? '...' : ''}
        </Typography>
      </Box>

      {/* Not present in this version */}
      {/* <Box marginTop='auto'>
        {categories.map(category => (
          <Chip
            sx={{
              marginBottom: '6px',
              marginRight: '6px',
            }}
            key={category}
            label={category}
            variant='results'
          />
        ))}
      </Box> */}
    </Stack>
  )
}

export default React.memo(ResourceCard)
